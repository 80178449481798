import React from 'react'
import Img from 'gatsby-image'

import ReactMarkdown from 'react-markdown'

const TeamMember = ({ data }) => {
  return (
    <article className='team-member'>
      <Img
        position='relative'
        className='team-member__image'
        fluid={data.image.childImageSharp.fluid}
      />
      <div className='team-member__content'>
        <h4 className='text-h4 lg:text-h3 text-center md:text-left '>
          {data.title}
        </h4>
        <h6 className='text-sm text-uppercase text-red text-center md:text-left'>
          {data.position}
        </h6>
        <ReactMarkdown
          className='team-member__description markdown-content'
          source={data.description}
        />
      </div>
    </article>
  )
}

export default TeamMember
