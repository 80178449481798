import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Layout from "@/components/Layout";
import ReactMarkdown from "react-markdown";
import TeamMember from "@/components/TeamMember/TeamMember";
import Helmet from "react-helmet";
import SEO from "@/helpers/SEOHelper.js";

import ContactButton from "../components/ContactButton";

export const AboutPageTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges: teamMembers } = data.people;
  const contactData = data.contact.edges[0].node.frontmatter;
  const seo = frontmatter.seo;
  const history = data.history.edges[0].node;
  const values = data.values.edges[0].node;
  const trends = data.trends.edges;

  return (
    <>
      <Helmet>
        <meta name="description" content={SEO.description(seo.description)} />
        <meta property="og:title" content={SEO.title(seo.title)} />
        <meta
          property="og:description"
          content={SEO.description(seo.description)}
        />
        <meta property="og:image" content={SEO.image(seo.image)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>

      <div className="min-h-screen ">
        <section className="hero mb-40">
          <div className="container">
            <ReactMarkdown
              source={frontmatter.hero}
              className="max-w-7xl mx-auto markdown-content text-center"
            />
          </div>
        </section>
        <section className="mt-32 mb-32">
          <div className="container flex flex-col lg:flex-row items-center justify-between pt-3 pb-3">
            <div className="flex flex-col justify-center items-center lg:items-start lg:justify-start flex-1 pt-20 pb-20">
              <h2 className="mb-6">{history.frontmatter.title}</h2>
              <div
                className="markdown-content mb-10 text-black text-center lg:text-left leading-6"
                dangerouslySetInnerHTML={{ __html: history.html }}
              ></div>
              <ContactButton lgJustify="start"/>
            </div>
            <div className="flex justify-center lg:justify-end items-center flex-1 self-stretch ">
              <div
                className="max-h-card max-w-card bg-black w-full h-full min-h-card max-w-card shadow-cardRed bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${history.frontmatter.image.publicURL})`,
                }}
              ></div>
            </div>
          </div>
        </section>
        <section className="mt-32 mb-32">
          <div className="container">
            <h4 className="text-center">{frontmatter.trendsHeading}</h4>
            <div className="card-grey__wrap card-grey__wrap--wide justify-center mb-16">
              {trends.map((trendItem, index) => (
                <div className="card-outer  w-full">
                  <div className="card-grey card-grey--s">
                    <h3 className="text-red">{index + 1}</h3>
                    <div className="mt-5 mb-6 markdown-content text-black leading-6">
                      {trendItem.node.frontmatter.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center items-center flex-col">
              <div className="text-h2 text-red mb-16">&darr;</div>
              <h4 className="text-center  mb-8">
                {frontmatter.solutionHeading}
              </h4>
              <p className="max-w-3xl text-center text-black leading-6">
                {frontmatter.solutionParagraph}
              </p>
            </div>
          </div>
        </section>
        <section className="mt-64 mb-64">
          <div className="container flex flex-col lg:flex-row items-center justify-between pt-3 pb-3">
            <div className="flex justify-center lg:justify-start items-center flex-1 self-stretch ">
              <div
                className="max-h-card max-w-card bg-black w-full h-full min-h-card shadow-cardRed bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${values.frontmatter.image.publicURL})`,
                }}
              ></div>
            </div>
            <div className="flex flex-col justify-center items-center lg:items-start lg:justify-start flex-1 pt-20 pb-20">
              <h2 className="mb-6">{values.frontmatter.title}</h2>
              <div
                className="markdown-content mb-10 text-black text-center lg:text-left leading-6"
                dangerouslySetInnerHTML={{ __html: values.html }}
              ></div>
              <ContactButton  lgJustify="start"/>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="team-feed">
              {frontmatter.team.map((member, i) => {
                let person = teamMembers.find(({ node: person }) => {
                  return member === person.frontmatter.title;
                });
                if (person) {
                  return (
                    <TeamMember
                      key={`${i}${person.id}`}
                      data={person.node.frontmatter}
                    />
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </div>
        </section>
        <section className="pt-20 pb-40 bg-gradient">
          <div className="container">
            <div
              dangerouslySetInnerHTML={{
                __html: data.contact.edges[0].node.html,
              }}
              className="max-w-7xl markdown-content text-white text-center mx-auto"
            />
            <div className="mx-auto flex flex-col md:flex-row justify-between max-w-5xl mt-13">
              <article className="card-dark card-dark--m mt-7 md:mt-0 text-white w-full">
                <ReactMarkdown
                  source={contactData.person}
                  className="text-left markdown-content text-white h6-uppercase "
                />
                <ReactMarkdown
                  source={contactData.hq}
                  className="markdown-content text-left mt-13 text-white"
                />
              </article>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const TeamPage = ({ data }) => {
  return (
    <Layout hideNav={true}>
      <AboutPageTemplate data={data} />
    </Layout>
  );
};

TeamPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "about" } }) {
      frontmatter {
        title
        hero
        team
        trendsHeading
        solutionHeading
        solutionParagraph
        seo {
          description
          title
        }
      }
    }
    people: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "team-member" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            position
            image {
              childImageSharp {
                fluid(maxWidth: 450, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
          }
        }
      }
    }
    history: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "history" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            image {
              publicURL
            }
          }
        }
      }
    }
    trends: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "trends" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
          }
        }
      }
    }
    values: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "values" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            image {
              publicURL
            }
          }
        }
      }
    }
    contact: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "contact" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            hq
            or
            person
          }
        }
      }
    }
  }
`;

export default TeamPage;
